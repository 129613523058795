import React, {useState} from "react";

import {Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './Login.scss'
import {useAuth} from "../../auth/AuthProvider";
import {useTranslation} from "react-i18next";

function Login() {
  const { t } = useTranslation();
  const {login} = useAuth()
  const loading = false
  const authSuccess = false

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState("");

  const notify = (message = t('username-password-incorrectly')) => toast.error(message) && setError(message);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      login(username, password).catch(error => {
        if (error.response) {
          const status = error.response.status
          if (status >= 400 && status < 500) {
            notify()
          } else {
            notify(t('error-occurred'))
          }
        }
      })
    } else {
      notify(t('necessary-fill-all-fields'));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  }

  return (
    <div className={`${loading ? "loading" : ""} loginWrapper mb-3 app flex-row align-items-center`}>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="6" xl="5">
            <Row className="justify-content-center">
              <img src="/img/vcharge_logo.png" alt="Zevs_logo" className="loginLogo shake"/>
            </Row>
            <Card style={{height: 'auto'}} className="p-4">
              <CardBody>
                <h1>{t('entrance')}</h1>
                <p className="text-muted">{t('log-your-account')}</p>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-user"/>
                      </span>
                    </div>
                  </InputGroupAddon>
                  <Input
                    placeholder="username"
                    autoComplete="username"
                    onChange={event => setUsername(event.target.value)}
                    onKeyPress={handleKeyPress}
                    error={error}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-key"/>
                      </span>
                    </div>
                  </InputGroupAddon>
                  <Input
                    placeholder="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={event => setPassword(event.target.value)}
                    onKeyPress={handleKeyPress}
                    error={error}
                  />
                </InputGroup>
                <div className="row">
                  <div className="col-6">
                    <Button color="primary" disabled={loading} onClick={handleLogin}>
                      {/* { loading ? `${ recovery ? "Отправка" : "Авторизация" }` : `${ recovery ? "Сбросить пароль" : "Войти" }` } */}
                      {loading && !authSuccess ? t('authorization') : t('enter')}
                    </Button>
                  </div>
                  {/* <div className="col-6 text-right">
                    <Button color="link" onClick={passRecovery}>
                      { recovery ? "Авторизация" : "Забыли пароль?" }
                    </Button>
                  </div> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
