import React, {Suspense, useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {Button, Container} from 'reactstrap';

import {
  AppBreadcrumb,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react';

import ConfirmDialog from '../../components/ConfirmDialog';

import {navigation} from '../../constants/_nav';
import routes from '../../routes';

import 'csshake/scss/csshake.scss';
import './default.scss'
import {useAuth} from "../../auth/AuthProvider";
import SwitchLanguages from "../../components/SwitchLanguages";
import {useTranslation} from "react-i18next";


const DefaultLayout = ({location}) => {
  const { t } = useTranslation();
  const {user, logout} = useAuth();
  const [language, setLanguage] = useState();


  const [isOpenMenu, setIsOpenMenu] = useState(localStorage.getItem('isOpenMenu') || "")
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [toggleMenuVisibility, setToggleMenuVisibility] = useState(false)
  const [env] = useState(window.location.host.includes("dev.") ? "development"
    : window.location.host.includes("localhost") ? "localhost" : process.env.NODE_ENV)

  const minimiserRef = React.createRef();
  const navRef = React.createRef();

  useEffect(() => {
    ReactDOM.findDOMNode(minimiserRef.current).addEventListener('click', onHandleClickMinimize);

    setToggleMenuVisibility(false)

    const exitBtn = document.querySelector('.navside-exit-item')
    if (exitBtn) {
      exitBtn.addEventListener('click', onHandleClickExit);
    }
  }, [])

  useEffect(() => {
    setToggleMenuVisibility(false)
  }, [location.pathname])

  useEffect(() => {
    document.body.style = toggleMenuVisibility ? 'overflow:hidden' : 'overflow:auto'
  }, [toggleMenuVisibility])

  const navConfig = useMemo(() => {
    return {
      items: navigation(t).items.filter(item => {
        return item.permission ? user.permissions.includes(item.permission) : true
      })
    }
  }, [user.permissions, language, t])

  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse"/>
    </div>
  )

  const toggleMenu = () => {
    setToggleMenuVisibility(!toggleMenuVisibility)
  }

  const hideToggleMenu = () => {
    setToggleMenuVisibility(false)
  }

  const onHandleClickMinimize = (e) => {
    const isOpen = localStorage.getItem('isOpenMenu') === "isOpen" ? "" : "isOpen"
    setIsOpenMenu(isOpen)
    localStorage.setItem("isOpenMenu", isOpen)
  }

  const onHandleClickExit = (e) => {
    setIsOpenConfirmDialog(true)
    e.preventDefault()
  }

  const toggleConfirmDialog = value => {
    setIsOpenConfirmDialog(value)
  }

  const handleLogoutConfirm = () => {
    logout()
  }

  return (
    <div env={env} className="app">
      {location.pathname === "/" ? <Redirect to={{pathname: navConfig.items[0].url}}/> : null}
      <div className="app-body">
        <AppSidebar open={isOpenMenu.length ? true : false} minimized={isOpenMenu.length ? true : false} display="lg">

          <div className="defaultLogo"/>
          <div className="avatarOverlay"/>

          <AppSidebarHeader/>
          <AppSidebarForm/>
          <AppSidebarNav navConfig={navConfig} ref={navRef}/>

          <AppSidebarFooter/>
          <AppSidebarMinimizer ver="v29.03" ref={minimiserRef}/>
        </AppSidebar>
        {toggleMenuVisibility && (
          <div>
            <div className="toggleMenuAround" onClick={hideToggleMenu}/>
            <div className={"mobileMenu"}>
              <AppSidebar className={"mobile"} fixed>
                <AppSidebarNav navConfig={navConfig}/>
              </AppSidebar>
            </div>
          </div>
        )}
        <main className="main">

          <div className={"mobileHeader"}>
            <header
              className="navbar navbar-light"
              style={{background: 'black'}}
            >
              <button
                className="navbar-toggler aside-menu-toggler-white"
                type="button"
                data-toggle="aside-menu-show"
                onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"/>
              </button>
              <img alt="ZEVS" src="/img/vcharge_logo.png"/> {/*logo mobila*/}
            </header>
          </div>
          <AppBreadcrumb appRoutes={routes} className={"appBreadcrumb"}/>
          <Container fluid style={{position: 'relative'}}>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component  setLanguage={setLanguage} {...props} />}
                    />
                  ) : null;
                })}
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
      <ConfirmDialog
        toggle={() => toggleConfirmDialog(false)}
        isOpen={isOpenConfirmDialog}
        onConfirm={handleLogoutConfirm}
        text={t('are-you-get-out')}
        buttonText={t('go-out')}
        buttonTextCancel={t('cancel1')}
        buttonTextSave={t('save1')}
      />
    </div>
  )
}

export default DefaultLayout
